import React from "react";
import Artykul from "../components/artykul";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/mity-o-crm.png";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: { eq: "mity-o-crm-pieniadze.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 399, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: { eq: "mity-o-crm telefon QuickCRM 7.1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 433, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    zdjecie5: file(
      relativePath: { eq: "system-crm-Baner-testuj.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const MityOCRM = ({ data }) => {
  return (
    <Artykul
      title="Mity o CRM, czyli jak nie myśleć o zarządzaniu relacjami z klientem"
      articleImage={obrazekArtykulu}
      keywords={["mity o crm"]}
      articleImageAlt="mity o crm"
      metaTitle="Fakty i mity o CRM – 6 mitów o zarządzaniu relacjami"
      metaDescription="✅ Wokół zarządzania relacjami z klientem narosło wiele mitów. Dziś pokażemy, które należy włożyć między bajki, a które są konsekwencjami wdrożenia CRM."
    >

<p>


Znajomość systemów CRM wśród przedsiębiorców stale rośnie. Szacuje się, że w przeciągu trzech lat rynek oprogramowania CRM osiągnie globalną wartość 26,6 miliarda dolarów. Tak ogromne pieniądze świadczą o tym, że firmy powinny być realnie zainteresowane wdrożeniem CRM. Jednak wciąż wiele osób żyje w przeświadczeniu, że takie systemy nie są im potrzebne. W ten sposób hamują niestety rozwój swojej firmy. Przedstawiamy dzisiaj mity o CRM, które pomogą Ci lepiej zrozumieć, czym jest zarządzanie relacjami z klientem i jak może pomóc w rozwoju Twojej firmy.

</p>
<h2>Jak zrozumieć i obalić mity o CRM?</h2>
<p>
Faktem jest, że wokół zarządzania relacjami z klientem narosło wiele mitów. Dziś postaramy się pokazać, które z nich należy włożyć między bajki, a które są realnymi konsekwencjami wdrożenia CRM. Dla osób, które nie wiedzą, czym jest CRM, polecamy <Link to="/crm-w-chmurze/">ten artykuł</Link>. Jego lektura pomoże dużo lepiej zrozumieć przedstawione poniżej mity o CRM.

</p>

<h3>Mit nr 1 – System CRM jest kosztownym wydatkiem</h3>

<p>
To przeświadczenie jest popularne w kraju nad Wisłą. Wielu przedsiębiorcom, szczególnie z segmentu MŚP, wydaje się, że oprogramowanie CRM musi być drogie.
Dlaczego jest to mit? Ponieważ CRM wcale nie musi być drogi.
Oczywiście, jeśli chcesz wdrożyć program, który będzie idealnie dopasowany do specyfiki Twojej firmy, lub jeśli oczekujesz dodatkowych rozwiązań, przygotowanych specjalnie dla Ciebie, zapłacisz więcej. Natomiast zupełnie inaczej wygląda sprawa podstawowego oprogramowania, którego cena rozpoczyna się już od kilku zł za użytkownika!
</p>
<Img
        style={{
          width: "0 auto",
          margin: "0 auto",
          maxWidth: 200
        }}
        
        title="Mity o CRM - pieniądze"
        alt="Mity o CRM - pieniądze"
        fluid={data.zdjecie1.childImageSharp.fluid}

      />
<p>
<br>
</br>
<Link to="/crm-cennik/">Sprawdź cenę swojego systemu.</Link>
Podstawowy pakiet funkcji CRM zawiera te rozwiązania, które są najbardziej potrzebne małej i średniej firmie:
<br>
</br>
    <li>Baza wiedzy o klientach,</li>
    <li>Procesy sprzedaży i szanse sprzedaży,</li>
    <li>Kalendarz, zadania i zdarzenia,</li>
    <li>Zarządzanie uprawnieniami,</li>
    <li>Komunikator,</li>
    <li>Inne</li>
<br>
</br>
</p>
 


<h3>Mit nr 2 – System CRM jest trudny w obsłudze i skomplikowany</h3>
<p>

Wielu przedsiębiorców nie jest ekspertami z dziedziny IT, a ich umiejętności informatyczne kończą się na wysyłce maili i wyszukiwaniu informacji w Google. Czy to jest bariera przy wdrożeniu CRM?
Nie. Wierzymy, że każdy jest ekspertem w swojej dziedzinie. My odpowiadamy za rozwiązania informatyczne, które mają wspierać Twój biznes. Ty znasz się na jego prowadzeniu. Dlatego nie musisz przejmować się skomplikowanymi procesami i możesz skupić się na swoim kliencie i jego potrzebach. System CRM dla małej i średniej firmy jest zbudowany tak, aby jego obsługa była prosta i przyjemna. Chcesz się przekonać? Sprawdź DEMO
 
</p>

<h3>Mit nr 3 – CRM jest potrzebny tylko w dużych firmach</h3>

<p>

Kolejny mit, który jest głęboko zakorzeniony w biznesowej świadomości. Oczywiście – duże firmy korzystają z systemów CRM i trudno sobie wyobrazić, żeby było inaczej. Jednak trzeba mieć świadomość, że każdy system jest inny. Istnieją duże, dedykowane rozwiązania i mniejsze, gotowe programy, takie jak QuickCRM. To oprogramowanie, które zostało zaprojektowane z myślą o małych przedsiębiorstwach, które chcą stopniowo zwiększać skalę działalności.
Dlaczego Twoja firma potrzebuje CRM?

    <li>CRM realnie zwiększa sprzedaż – statystyki mówią, że każdy dolar wydany na CRM zwraca 8.71$ w postaci zysku ze wzrostu sprzedaży (2014),</li>
    <li>CRM ułatwia planowanie pracy – kalendarz i zadania usprawniają planowanie i przebieg wszystkich procesów sprzedażowych,</li>
    <li>CRM pozwala skupić się na kliencie – nazwa nie bierze się znikąd. CRM pozwala wprowadzić strategię skupioną na kliencie i jego potrzebach,</li>
    <li>CRM ogranicza niepotrzebne czynności – oprogramowanie wyręcza człowieka w wielu sytuacjach, ogranicza monotonne i powtarzalne czynności, które wykonują Twoi pracownicy.</li>
</p>
 


<h3>Mit nr 4 – Wdrożenie CRM jest długie i niesie za sobą ukryte koszty</h3>
<p>

Wśród mitów o CRM nie mogło zabraknąć tego popularnego stereotypu. Większość ludzi sądzi, że wdrożenie CRM trwa długo – to kolejny przypadek, w którym należy zaznaczyć, że nie każdy CRM jest taki sam. Różnią się one od siebie wieloma czynnikami, dostępnymi funkcjami, czy integracjami z innym oprogramowaniem. Bez obaw można stwierdzić, że dostarczenie prostego systemu trwa zaledwie kilka chwil. Czas, potrzebny na implementację CRM do działu handlowego zależy od wielu czynników. Jeśli Twój zespół jest niewielki i wie, w czym ma pomagać system CRM, wdrożenie będzie sprawne i zajmie niewiele czasu.
<br>
</br>
<br>
</br>
<strong>Co z ukrytymi kosztami?</strong>
<br>
</br>
Stawiamy na otwartość i przejrzystość warunków. Wszystkie koszty wynikają bezpośrednio z umowy, którą zawieramy przy wdrożeniu. Nic nie jest dopisane drobnym drukiem, a nasi sprzedawcy są gotowi odpowiedzieć na wszystkie Twoje pytania.
<br>
</br>
<br>
</br>
<strong>Co z kosztami po wdrożeniu?</strong>
<br>
</br>
To zależy od Ciebie. Jeśli będziesz chciał podnieść system do wyższej wersji, lub zdecydujesz się w przyszłości na większe rozwiązanie – koszty mogą wzrosnąć. Jednak wdrażając konkretny system CRM doskonale wiesz, ile będzie Cię kosztował i co w sobie zawiera.
 <br>
</br>
<br>
</br>

</p>

<h3>Mit nr 5 – oprogramowanie działa tylko w biurze, nie przyda się w terenie</h3>

<p>
To olbrzymi błąd w rozumowaniu. Dużą zaletą systemu CRM jest właśnie jego mobilność. Trzeba pamiętać, że żyjemy w XXI wieku, gdzie technologia chmury jest wykorzystywana praktycznie wszędzie. Dlaczego nie zastosować jej w Twoim CRM-ie? Wiele systemów, dostępnych na rynku, wciąż oferuje interfejs oparty na oknach Windowsa lub prostych formularzach. Podstawową przewagą systemu QuickCRM jest wykorzystanie technologii SaaS, która pozwala na obsługę systemu za pomocą przeglądarki internetowej.
<br>
</br>
<br>
</br>
<Img
        style={{
          width: "0 auto",
          margin: "0 auto",
          maxWidth: 300
        }}
        
        title="mity o crm mobilny"
        alt="mity o crm mobilny"
        fluid={data.zdjecie2.childImageSharp.fluid}

      />

<br>
</br>
<strong>Jak to działa?</strong>
<br>
</br>
Po zakupie otrzymujesz link do systemu, logujesz się i już! Dostęp do Twoich zasobów w każdym miejscu na Ziemi, z każdego urządzenia (także Twojego smartfona!) i w każdej chwili daje Ci przewagę nad konkurencją. Prowadzenie firmy jeszcze nigdy nie było tak proste.
</p> 


<h3>Mit nr 6 – handlowcy nie chcą korzystać z CRM</h3>
<p>
To mit, który swoje źródło bierze najczęściej w nieudanych wdrożeniach, gdzie użytkownicy systemu nie odczuwali potrzeby korzystania z niego. Istnieją handlowcy, dla których CRM jest tylko dodatkowym obowiązkiem, do którego trzeba przykładać uwagę. Nie zauważają oni, jakie potencjalne korzyści niesie za sobą użytkowanie CRM-u. Jedną rzecz trzeba powiedzieć na początku – system CRM ma pomagać w długim okresie przy wszystkich czynnościach sprzedażowych, pozwalając w jak największym stopniu automatyzować czynności powtarzające się. Jeśli to zrozumiesz, otworzy się przed Tobą inna perspektywa: przeszkolenie pracowników i przyzwyczajenie ich do korzystania z systemu CRM realnie przyspieszy procesy sprzedażowe i pomoże handlowcom lepiej zorientować się w sytuacji klientów.
<br>
</br>
<br>
</br>
   <cite>Spójrzmy na to od strony praktycznej. Wyobraź sobie sytuację, w której jeden z Twoich handlowców zachorował i nie przyszedł do pracy. Zadzwonił do Ciebie klient, który jest już gotowy do zakupu Twojego produktu. Okazuje się, że dotychczas obsługiwał go wspomniany handlowiec i to on jest w posiadaniu wszystkich informacji o ustaleniach, etapie negocjacji itd.</cite>
<br>
</br>
<br>
</br>
<strong>Jak poradzić sobie z taką sytuacją?</strong>
<br>
</br>

Prawda będzie dla wielu bolesna: bez CRM-u jesteś bezradny. Nie wiesz kim jest ten klient, jakie są jego potrzeby, jak długo rozmawia z Twoją firmą. Wszystkie te informacje mogły być zapisane w systemie CRM. Dzięki temu w kilka sekund wyszukałbyś kontrahenta i sprawdził historię jego współpracy z Twoją firmą.
<br>
</br>
<br>
</br>
<strong>Pójdźmy dalej i załóżmy, że handlowcy nie korzystają z CRM</strong>
<br>
</br>

Jeśli klient nie został prawidłowo obsłużony, uda się do konkurencji, która będzie dbać o budowanie i utrzymywanie relacji. To oczywiste, że taniej i łatwiej jest utrzymać obecnych klientów, niż pozyskiwać nowych. Stąd właśnie biorą się największe oszczędności, które stoją za CRM. Nie musisz już poświęcać olbrzymich środków na działania marketingowe, bo masz względnie stałą i wierną pulę odbiorców. Nikt nie zabrania Ci jej poszerzać, ale musisz też dbać o to, żeby aktualni klienci nie odwracali się od Ciebie. Właśnie dla tych celów został stworzony system CRM.
<br>
</br>
<br>
</br>
</p>

<h3>Fakty i mity o CRM – podsumowanie</h3>
<p>
Istnieje wiele zjawisk, które ludzie próbują sobie wyjaśniać za pomocą stereotypów i mitów. Takie uproszczenia ułatwiają nam klasyfikację elementów naszego otoczenia. Dobrze jest jednak mieć świadomość tego, jak wygląda rzeczywistość.
Mity o CRM mogą niestety prowadzić do niepożądanych skutków. Jeśli z badań wynika, że w ciągu roku 2013 liczba firm, korzystających z CRM wzrosła z 56% do 74%, to faktem staje się to, że Twoja konkurencja prędzej czy później wdroży taki system. Jeśli nie chcesz zostać w tyle, pozbądź się mitów i zastanów się nad wyborem najlepszego rozwiązania CRM.
</p>
<Link to="/demo-crm/">
        <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        
        title="Demo systemu CRM - mity o crm"
        alt="Demo systemu CRM - mity o crm"
        fluid={data.zdjecie5.childImageSharp.fluid}

      />
      </Link>
    </Artykul>
    
  );
};

export default MityOCRM;